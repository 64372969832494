import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  isJson(value: string) {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
  }
}
