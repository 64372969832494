import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './components/shared/header/header.component';
import { ApiContentTypeEnum, Api, ApiSpecificationTypeEnum } from './models/speedpost-data.model';
import { SpeedPostContentModel } from './models/speedpost-data.model';
import { MockMessageEvent } from './models/message.mock.model';
import { MessageEventMockData } from './services/contract/contract.mock';
import { environment } from '@environments/environment';
import { OasSpecificationComponent } from './components/oas/oas-specification.component';
import { WsdlSpecificationComponent } from './components/wsdl/wsdl-specification.component';

@Component({
  selector: 'app-root-speedpost',
  standalone: true,
  imports: [CommonModule, HeaderComponent, OasSpecificationComponent, WsdlSpecificationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit{
  title = 'speedpost';
  loading = true;
  contractDetails: SpeedPostContentModel = null as unknown as SpeedPostContentModel;
  errorLoadingApp = false;
  errorMessage = "";
  apisArray: Api[] = [];
  activeAPIObject: Api = this.apisArray[0];
  productionDetails: { key: string; secretId: string, name: string}[] = [];
  domainAllowed = false;
  showBodyContent: boolean = true;
  contractObjectAPI: SpeedPostContentModel = null as unknown as SpeedPostContentModel;
  strategyType!: string;
  @ViewChild('OAS3SpecType') OAS3SpecTemplate!: TemplateRef<OasSpecificationComponent>;
  @ViewChild('WSDLSpecType') WSDLSpecTemplate!: TemplateRef<OasSpecificationComponent>;

  constructor(private cdr: ChangeDetectorRef) {
    if (environment.environmentName === "Dev") {
        console.log("Environment - ", environment.environmentName);
        this.receiveMessage(MessageEventMockData);
    } else if (typeof window !== "undefined") {
      window.addEventListener('message', this.receiveMessage.bind(this), false);
    }
  }

  setDomainAllowed(origin: string) {
    environment.allowedDomains.find(allowedDomain => {
      if (allowedDomain === origin) this.domainAllowed = true;
    })
    if (origin.endsWith(".digitalapicraft.com")) {
      this.domainAllowed = true
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  async receiveMessage(event: MessageEvent|MockMessageEvent) {
    this.setDomainAllowed(event.origin);
    if (this.domainAllowed) {
      if (event.data) {
        if (event.data.type === ApiContentTypeEnum.ENDPOINTS &&
          event.data.apiName && event.data.apiVersion &&
          event.data.type && event.data.apiSpecificationType &&
          event.data.fileExtension && event.data.contractType &&
          event.data.fileContent) {
          this.contractDetails = event.data;
          if(event.data && event.data.subscriptions) {
            event.data.subscriptions.forEach((subscription:  {clientId: string,
              clientSecret: string,
              name: string}
            ) => {
              this.productionDetails.push({
                key : subscription.clientId,
                secretId : subscription.clientSecret,
                name : subscription.name ? subscription.name : 'API Subscription'
              });
            });
          }
          this.loading = false;
          if (this.checkStrategyType(event.data)) {
            this.strategyType = event.data.apiSpecificationType;
            this.contractObjectAPI = event.data;
          } else {
            this.loading = false;
            this.errorLoadingApp = true;
            this.errorMessage = "Uploaded details not supported. Please verify the uploaded contract.";
          }
        }
      } else {
        this.loading = false;
        this.errorLoadingApp = true;
        this.errorMessage = "Request not allowed";
      }
    } else {
      console.log("Returning as the origin did not match");
      return
    }
  }

  checkStrategyType(eventData: SpeedPostContentModel){
    return eventData.apiSpecificationType === ApiSpecificationTypeEnum.OAS2 || eventData.apiSpecificationType === ApiSpecificationTypeEnum.OAS3
      || eventData.apiSpecificationType === ApiSpecificationTypeEnum.WSDL;
  }

  onErrorMessageDisplay(message: string){
    this.errorMessage = message;
  }

  onErrorLoading(errorLoad: boolean){
    this.errorLoadingApp = errorLoad;
  }

  loadSpecTemplate(specType: string) {
    switch (specType){
      case 'OAS3':
        return this.OAS3SpecTemplate;
      case 'WSDL':
        return this.WSDLSpecTemplate;
      default:
        return this.OAS3SpecTemplate;
    }
  }
}